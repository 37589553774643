import { FooterLink, FooterParagraph } from 'components/lib';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { NMLScode, links, disclaimer } from 'src/data/lang/footer.lang';
import { FooterContainer } from './footer.styles';
import LogoKellerHome from '../svg/LogoKellerHome';

const Footer = () => {
  return (
    <FooterContainer>
      <div className="wrapper">
        <div className="logo">
          <LogoKellerHome fillMortage="#ffffff" fillAccent="#ffffff" />
          <div className="links">
            {links.map(link => (
              <Link href={link.href} passHref target="_blank" key={link.title}>
                <FooterLink>{link.title}</FooterLink>
              </Link>
            ))}
          </div>
        </div>
        <div className="disclaimer">
          <div className="code">
            <Image
              src="/img/equal.png"
              height={46}
              width={100}
              alt="Equal Housing Logo"
              quality={100}
            />
            <div className="code-content">
              <FooterParagraph>{NMLScode.code}</FooterParagraph>
              <a href={NMLScode.titleHref} target="_blank" rel="noreferrer">
                {NMLScode.title}
              </a>
            </div>
          </div>
          <FooterParagraph dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
