import styled from 'styled-components';

// Headings

export const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 96px;
  line-height: 108px;
  letter-spacing: -1.5px;
  font-family: filson-pro, sans-serif;
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 64px;
    line-height: 72px;
    letter-spacing: -1px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -1px;
  }
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -1px;
  font-family: filson-pro, sans-serif;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 36px;
    line-height: 40px;
  }
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -1px;
  font-family: filson-pro, sans-serif;
  font-weight: bold;
`;

export const H4 = styled.h4`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 24px;
  line-height: 32px;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  display: block;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const H5 = styled.h5`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 20px;
  line-height: 24px;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors.navy};
  font-size: 14px;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
`;
export const H7 = styled.h6`
  color: ${({ theme }) => theme.colors.navy};
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
// Paragraph

export const Paragraph = styled.p<{ size?: 'large' | 'small' }>`
  font-size: ${({ size }) => (size === 'large' ? '20px' : '16px')};
  line-height: ${({ size }) => (size === 'large' ? '28px' : '24px')};
  font-family: proxima-nova, sans-serif;
  font-weight: normal;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ size }) => (size === 'large' ? '16px' : '12px')};
    line-height: ${({ size }) => (size === 'large' ? '24px' : '20px')};
  }
`;
export const LI = styled.li`
  font-size: 20px;
  line-height: 28px;
  font-family: proxima-nova, sans-serif;
  font-weight: normal;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Paragraph2 = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.navy};
`;

export const About = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  margin: 0px;
`;

export const ToolTipText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  margin: 0;
  white-space: pre-wrap;

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const InputParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
`;

// Others

export const PullQuote = styled.p`
  font-size: 48px;
  line-height: 56px;
  font-family: filson-pro, sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
`;

export const Testimonial = styled.p`
  font-size: 48px;
  line-height: 60px;
  font-family: filson-pro, sans-serif;
  font-weight: 300;
`;

export const Link = styled.a`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.navy};
  position: relative;
  display: block;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 3px;
    bottom: -5px;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.green};
    &::after {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.navy};
    &::after {
      background-color: transparent;
    }
  }
`;

export const Label = styled.label`
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.navy};
  display: block;
  width: max-content;
  margin-bottom: 4px;
  .required-mark {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const Tag = styled.span`
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.navy};
  border: 1px solid ${({ theme }) => theme.colors.navy};
  border-radius: 8px;
  padding: 4px 8px;
  display: inline-block;
  width: max-content;
`;

export const FooterLink = styled.p`
  font-family: proxima-nova, sans-serif;
  font-weight: 200;
  font-size: 1.4rem;
  line-height: 0px;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.4rem;
  }
`;
export const FooterParagraph = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;
export const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.colors.navyLight};
  font-size: 24px;
  line-height: 32px;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  display: block;
`;
