interface LogoProps {
  fillMain?: string;
  fillAccent?: string;
  fillMortage?: string;
  height?: string;
  width?: string;
}
const LogoKellerHome = ({
  fillMain,
  fillAccent,
  fillMortage,
  height,
  width,
}: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 384.11 93.91"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fillMain}
          d="m4.04,66.73c.12.37.25.73.38,1.09,5,13.74,18.08,22.97,32.53,22.97s27.58-9.26,32.56-23.05c.12-.33.24-.67.35-1.01h-6.58c-4.39,10.7-14.82,17.8-26.32,17.8s-21.96-7.09-26.34-17.8h-6.57Z"
        />
        <g>
          <path
            fill={fillAccent}
            d="m56.71,40.28c-7.89,1.58-13.05,5.2-16.39,8.9v-24.78h-6.76v17.78c-3.34-3.7-8.5-7.32-16.39-8.9l-1.33,6.63c15.58,3.12,17.55,15.87,17.72,17.3v19.8h6.76v-12.79c.18-1.5,2.18-14.19,17.72-17.3l-1.33-6.63Z"
          />
          <path
            fill={fillAccent}
            d="m58.16,19.22c-2.24-9.86-11-16.9-21.22-16.9s-18.98,7.04-21.22,16.9C6.41,21.89,0,30.34,0,40.13c0,12,9.76,21.76,21.76,21.76v-6.76c-8.27,0-15-6.73-15-15,0-7,4.75-13.01,11.55-14.61,1.99-.47,3.5-2.06,3.85-4.07,1.26-7.17,7.48-12.38,14.78-12.38s13.51,5.21,14.78,12.38c.35,2.01,1.86,3.6,3.85,4.07,6.81,1.6,11.56,7.6,11.56,14.61,0,8.27-6.73,15-15,15v6.76c12,0,21.76-9.76,21.76-21.76,0-9.8-6.42-18.25-15.73-20.92Z"
          />
        </g>
      </g>
      <g>
        <polygon
          fill={fillAccent}
          points="122.94 18.88 122.94 36.96 132.24 36.96 132.24 34.63 125.54 34.63 125.54 18.88 122.94 18.88"
        />
        <polygon
          fill={fillAccent}
          points="133.9 18.88 133.9 36.96 143.2 36.96 143.2 34.63 136.5 34.63 136.5 18.88 133.9 18.88"
        />
        <polygon
          fill={fillAccent}
          points="144.88 18.88 144.88 36.96 155.38 36.96 155.38 34.63 147.48 34.63 147.48 29.01 154.88 29.01 154.88 26.67 147.48 26.67 147.48 21.21 155.14 21.21 155.14 18.88 144.88 18.88"
        />
        <path
          fill={fillAccent}
          d="m159.67,26.78v-5.68h1.67c2.52,0,3.53,1.04,3.56,2.73.03,1.52-.77,2.95-3.56,2.95h-1.67Zm9.05,10.18l-3.35-8.45c1.99-1.65,2.22-2.76,2.2-4.57-.03-3.13-2.28-5.06-5.94-5.06h-4.56v18.09h2.6v-8.09s2.15,0,3.1,0l3.27,8.09h2.69Z"
        />
        <polygon
          fill={fillAccent}
          points="110.76 18.88 110.76 36.93 121.24 36.93 121.24 34.6 113.36 34.6 113.36 28.99 120.74 28.99 120.74 26.66 113.36 26.66 113.36 21.21 121.01 21.21 121.01 18.88 110.76 18.88"
        />
        <polygon
          fill={fillAccent}
          points="106.13 18.88 105.22 19.87 98.56 27.12 98.56 18.88 95.96 18.88 95.96 36.9 98.56 36.9 98.56 30.71 101.44 27.55 105.22 36.9 107.9 36.9 107.9 36.9 106.96 34.57 103.29 25.52 107.23 21.2 109.36 18.88 106.13 18.88"
        />
      </g>
      <g>
        <path
          fill={fillMortage}
          d="m116.81,83.43v-13.16h-12.02v13.16h-8.83v-34.91h8.83v14.66h12.02v-14.66h8.83v34.91h-8.83Z"
        />
        <path
          fill={fillMortage}
          d="m143.59,83.98c-8.03,0-14.56-5.98-14.56-13.46s6.53-13.46,14.56-13.46,14.56,6.03,14.56,13.46-6.48,13.46-14.56,13.46Zm0-7.08c3.69,0,6.28-2.64,6.28-6.38s-2.59-6.43-6.28-6.43-6.28,2.69-6.28,6.43,2.59,6.38,6.28,6.38Z"
        />
        <path
          fill={fillMortage}
          d="m185.32,69.37v14.06h-8.58v-14.11c0-2.69-.85-5.39-3.49-5.39s-3.54,2.54-3.54,5.44v14.06h-8.58v-25.83h8.58v3.44c1.35-2.49,3.74-3.99,6.53-3.99,3.69,0,6.13,1.65,7.43,4.09,2.29-3.59,5.49-4.09,8.08-4.09,7.23,0,9.18,6.33,9.18,11.92v14.46h-8.58v-14.11c0-2.64-.85-5.39-3.54-5.39s-3.49,2.54-3.49,5.44Z"
        />
        <path
          fill={fillMortage}
          d="m203.62,70.61c0-7.38,5.54-13.56,14.41-13.56,7.83,0,13.76,4.84,13.76,13.96,0,.35,0,.75-.05,1.1h-19.9c.1,2.34,2.79,4.99,7.68,4.99,2.49,0,4.84-.9,7.08-2.79.7.7,3.54,4.24,4.24,4.89-3.44,3.64-7.88,4.79-12.07,4.79-9.57,0-15.16-5.98-15.16-13.36Zm8.33-3.09h11.77c-.3-2.94-3.19-4.09-5.73-4.09s-5.53,1.1-6.03,4.09Z"
        />
        <path
          fill={fillMortage}
          d="m267.65,76.15v7.28h-24.53v-34.91h8.83v27.63h15.71Z"
        />
        <path
          fill={fillMortage}
          d="m282.8,83.98c-8.03,0-14.56-5.98-14.56-13.46s6.53-13.46,14.56-13.46,14.56,6.03,14.56,13.46-6.48,13.46-14.56,13.46Zm0-7.08c3.69,0,6.28-2.64,6.28-6.38s-2.59-6.43-6.28-6.43-6.28,2.69-6.28,6.43,2.59,6.38,6.28,6.38Z"
        />
        <path
          fill={fillMortage}
          d="m327.78,57.6v25.83h-8.58v-3.54c-1.65,2.54-4.19,4.09-7.83,4.09-7.43,0-12.47-5.88-12.47-13.51s4.84-13.41,12.42-13.41c3.39,0,6.28,1.6,7.88,3.99v-3.44h8.58Zm-14.36,19.65c3.39,0,5.98-2.44,5.98-6.53s-2.44-6.88-6.08-6.88-5.78,2.99-5.78,6.63,2.24,6.78,5.88,6.78Z"
        />
        <path
          fill={fillMortage}
          d="m332.32,57.6h8.58v3.39c1.84-2.74,4.39-3.94,7.68-3.94,7.53,0,9.13,6.28,9.13,11.92v14.46h-8.58v-13.96c0-2.49-.95-5.54-4.09-5.54s-4.14,2.59-4.14,5.54v13.96h-8.58v-25.83Z"
        />
        <path
          fill={fillMortage}
          d="m366.27,74.2c.65,2.74,2.89,3.54,4.94,3.54,1.55,0,2.99-.5,2.99-1.7,0-.7-.45-1.1-1.84-1.7l-4.29-1.79c-4.09-1.5-6.88-4.04-6.88-7.68,0-4.74,4.74-7.83,10.52-7.83,3.59,0,7.58,1.2,9.82,5.88l-6.43,2.44c-.65-1.9-2.44-2.29-3.49-2.29-1.4,0-2.44.7-2.44,1.75,0,.9.85,1.4,2.14,1.85l3.44,1.4c5.98,2.19,7.48,5.24,7.48,7.98,0,5.19-5.24,7.93-11.12,7.93-4.79,0-9.67-1.79-11.07-7.28l6.23-2.49Z"
        />
      </g>
    </svg>
  );
};

LogoKellerHome.defaultProps = {
  fillMain: '#20A85F',
  fillAccent: '#29454D',
  fillMortage: '#20A85F',
  height: '48px',
  width: '200px',
};

export default LogoKellerHome;
