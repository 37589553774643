import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 16px;
  }
`;

export const GridContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 16px;
  padding: 0 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-gap: 8px;
    padding: 0 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-gap: 4px;
    padding: 0 16px;
  }
`;

export const Grid = styled.div<{
  columns?: number;
  mediumColumns?: number;
  smallColumns?: number;
}>`
  display: grid;

  grid-template-columns: repeat(
    ${({ columns }) => columns || 12},
    minmax(0, 1fr)
  );
  grid-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-gap: 8px;
    grid-template-columns: repeat(
      ${({ mediumColumns, columns }) => mediumColumns || columns || 12},
      minmax(0, 1fr)
    );
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(
      ${({ smallColumns, mediumColumns, columns }) =>
        smallColumns || mediumColumns || columns || 12},
      minmax(0, 1fr)
    );
    grid-gap: 4px;
  }
`;

export const Col = styled.div<{
  span?: number | string;
  smallSpan?: number | string;
  mediumSpan?: number | string;
  xsSpan?: number | string;
}>`
  grid-column: ${({ span }) => span || '1/13'};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: ${({ mediumSpan, span }) => mediumSpan || span || '1/13'};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: ${({ smallSpan, mediumSpan, span }) =>
      smallSpan || mediumSpan || span || '1/13'};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: ${({ xsSpan, smallSpan, mediumSpan, span }) =>
      xsSpan || smallSpan || mediumSpan || span || '1/13'};
  }
`;

export const RoundedCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 28px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.small};
`;

export const ResponsiveIframe = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0px;
  }
`;
