import styled from 'styled-components';

const BaseButton = styled.button<{ fluid?: boolean }>`
  border: none;
  border-radius: 18px;
  padding: 8px 20px;
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  ${({ fluid }) => fluid && 'width: 100%'}
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  border: 1.5px solid transparent;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.green};
    border: 1.5px solid ${({ theme }) => theme.colors.green};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGreen2};
    color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid transparent;
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(BaseButton)<{
  round?: boolean;
  color?: 'primary' | 'secondary';
}>`
  background-color: transparent;
  z-index: 0;
  color: ${({ theme, color }) =>
    color && color === 'secondary' ? theme.colors.navy : theme.colors.green};
  border: 1.5px solid
    ${({ theme, color }) =>
      color && color === 'secondary' ? theme.colors.navy : theme.colors.green};
  padding: ${({ round }) => (round ? '12px' : '')};
  &:hover {
    background-color: ${({ theme, color }) =>
      color && color === 'secondary' ? theme.colors.navy : theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active {
    background-color: transparent;
    color: ${({ theme, color }) =>
      color && color === 'secondary' ? theme.colors.navy : theme.colors.green};
  }
  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.lightGreen2};
    border: 1.5px solid ${({ theme }) => theme.colors.lightGreen2};
    cursor: not-allowed;
  }
`;

export const TertiaryButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.navy};
  border: 1.5px solid transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  &:hover {
    border: 1.5px solid ${({ theme }) => theme.colors.navy};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1.5px solid transparent;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled.button`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.mediumGray};
  }
`;
