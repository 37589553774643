import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const FooterContainer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.navy};
  color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  .wrapper {
    max-width: 1392px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding: 64px 32px;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .links {
        display: flex;
        text-decoration: underline;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  .disclaimer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    p {
      text-align: center;
    }
    a {
      text-decoration: underline;
      color: ${theme.colors.white};
    }
    .code {
      display: flex;
      gap: 16px;
      align-items: flex-end;
      .code-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2px;
        p {
          text-align: left;
        }
        a {
          text-decoration: none;
          line-height: 0.8rem;
        }
      }
    }
  }

  @media (max-width: ${theme.breakpoints.lg}) {
    .wrapper {
      padding: 32px;
      gap: 24px;
    }
  }
`;
