import styled from 'styled-components';

export const Input = styled.input<{
  variant?: 'default' | 'secondary';
}>`
  -webkit-appearance: none;
  background-color: ${({ theme, variant }) =>
    variant === 'secondary' ? theme.colors.lightGray : theme.colors.white};
  padding: 10px 6px 10px 12px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.navy};
  border-radius: 4px 4px 0 0;
  transition: box-shadow 0.2s;
  border: none;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  line-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.green};
    outline: none;
  }
  &:focus-within {
    box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.green};
  }
`;
export const TextArea = styled.textarea`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 8px 10px 12px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.navy};
  border-radius: 4px 4px 0 0;
  transition: box-shadow 0.2s;
  border: none;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  line-height: 20px;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  resize: none;
  &:hover,
  &:focus {
    box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.green};
    outline: none;
  }
  &:focus-within {
    box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.green};
  }
`;

// used for inputs with icons
export const InnerInput = styled.input`
  border: none;
  background-color: inherit;
  padding: 0 0 0 8px;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.navy};
  outline: none;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  label {
    font-family: 'proxima-nova', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${prop => prop.theme.colors.navy};
  }
`;

export const IconContainer = styled.div`
  margin-right: 15px;
  padding-left: 4px;
  span {
    user-select: none;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.navy};
    text-align: center;
  }
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.navyGray};
  height: 1px;
`;
